<div #recreateModal class="modal fade" tabindex="-1" id="recreateModal" aria-labelledby="recreateModal"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h4 class="m-0">{{"cartPage.recreateCartConfirmation"|cxTranslate}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <fieldset class="form-group mb-0">
          <p>{{"cartPage.recreateCartMessage"|cxTranslate}}</p>
        </fieldset>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button type="button" class="btn cstm-btn-primary" data-dismiss="modal">
          {{('bulkOrder.ok'|cxTranslate)}}</button>
      </div>
    </div>
  </div>
</div>