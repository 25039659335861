<app-table-template 
    [columns]="columns"
    [tableData]="cartsList | async"
    [isEdit]="isEdit"
    [message]="message"
    [allowQuotation]="true"
    (navigateClickEvent)="navigateToPDP($event)"
    (qtyUpdateClickEvent)="updateQty($event)"
    (deleteBtnClickEvent)="deleteCart($event)">
</app-table-template>
<app-recreate-modal-popup></app-recreate-modal-popup>
