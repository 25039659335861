import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CartService } from '../../services/cart.service';
import {
  Column,
  ColumnType,
} from '../../../../SGRE-shared/models/tableConfiguration';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { Router } from '@angular/router';
import { isNotNullable, isNotUndefined } from '@spartacus/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
})
export class CartComponent implements OnInit, OnDestroy {

  @Output() addToCartClk = new EventEmitter<any>();

  public columnType: any[];
  public isEdit = false;
  public columns: Column[];
  public cartsList: Observable<any>;
  public message = 'noProductsCart';
  public imageUrl: any = environment.siteUrls.getBackofficeUrl.slice(0, -1);
  public csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Cart Details',
    useBom: true,
    noDownload: false,
    headers: [
      'Part Number',
      'Product Name',
      'Variant',
      'Lead Time',
      'Unit Price',
      'Sales Unit',
      'Quantity',
      'Total',
    ],
  };
  private unsubscribe$ = new Subject<void>();

  constructor(
    private cartsService: CartService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getCartsList();
    this.createTableConfiguration();
    this.handleSubscriptions();
  }

  public getCartsList() {
    this.cartsService.getCartsList();
    this.cartsList = this.cartsService.data$;
  }

  handleSubscriptions() {
    // cart-change within Mini-Cart (setActiveCart())
    this.globalService.updateCart$
      .pipe(
        filter(isNotNullable),
        filter(isNotUndefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.getCartsList();
        }
      });

    // Mini-Cart GET API (getCartsListByLegalEntity())
    this.globalService.carts$
      .pipe(
        filter(isNotNullable),
        filter(isNotUndefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(data => {
        if (data) {
          this.globalService.loadingSubject.next(true);
          this.getCartsList();
        }
      })
  }

  createTableConfiguration() {
    let columnData = [
      { label: 'Image', name: 'imageUrl', type: ColumnType.image },
      { label: 'Part Number', name: 'partNumber', type: ColumnType.link },
      { label: 'Product Name', name: 'productName', type: ColumnType.link },
      { label: 'Variant', name: 'variant', type: ColumnType.text },
      { label: 'Lead Time', name: 'leadTime', type: ColumnType.number },
      { label: 'Unit Price', name: 'unitPrice', type: ColumnType.price },
      { label: 'Sales Unit', name: 'salesUnit', type: ColumnType.text },
      { label: 'Quantity', name: 'quantity', type: ColumnType.quantity },
      { label: 'Total', name: 'total', type: ColumnType.price },
      { label: 'Delete', name: 'delete', type: ColumnType.delete },
    ];
    this.columns = columnData.map((item) => new Column(item));
  }

  navigateToPDP(url) {
    this.router.navigate([url]);
  }

  public deleteCart(entryNumber) {
    this.cartsService.deleteCart(entryNumber)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          this.globalService.loadingSubject.next(true);
          this.cartsService.getCartsList();
          this.globalService.getCartsListByLegalEntity();
        },
        error: (error) => {
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  public updateQty(data) {
    let entryNumber = data.index;
    let qty = data.formArray[entryNumber]?.quantity;
    this.globalService.loadingSubject.next(true);
    this.cartsService.updateQty(entryNumber, qty)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          this.cartsService.getCartsList();
          this.globalService.loadingSubject.next(false);
        },
        error: (error) => {
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
