import {
  Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Subject, takeUntil } from 'rxjs';
declare let bootstrap: any;

@Component({
  selector: 'app-recreate-modal-popup',
  templateUrl: './recreate-modal-popup.component.html',
  styleUrl: './recreate-modal-popup.component.scss',
})
export class RecreateModalPopupComponent implements OnInit, AfterViewInit, OnDestroy {

  public modalInstance: any;
  @ViewChild('recreateModal') recreateModal: ElementRef;
  currentUrl: any;
  previousUrl: any;
  recreate: any;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private storageService: StorageService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.globalService.recreate$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x: any) => {
        this.recreate = x;
        if (this.recreate == true) {
          if (this.storageService.activeCart.name.includes('Recreated')) {
            this.showModal();
          }
        }
        $('.modal-backdrop').hide();
      });
  }

  showModal(): void {
    this.modalInstance = new bootstrap.Modal(this.recreateModal.nativeElement);
    this.modalInstance.show();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
